import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'gatsby';

import Layout from '../components/Layouts/Main';

const NotFoundPage = () => (
  <Layout navbarFixed blueLogo removeHeader>
    <div className="container kos__notfound">
      <div className="kos__notfound__container">
        <div className="kos__notfound__404">
          <h1 className="kos__notfound__h1">OOPS!</h1>
          <h2 className="kos__notfound__h2">404 - THE PAGE CAN'T BE FOUND</h2>
        </div>
        <Link
          to="/"
          className="button is-rounded kos__btn--green kos__notfound__btn"
        >
          <span>{'GO TO HOMEPAGE'}</span>
          <ChevronRightIcon />
        </Link>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
